import * as icons from '../../../assets/icons'
import { BigImagePickerAndEditor } from '../BigImage/subcomponents'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CRM_THIRD_PARTY_ICONS } from '@app/common/src/constants/crmThirdParty'
import { CUSTOM_CRM_TERMS_MAX, DESCRIPTION_MAX } from '../validation'
import { ChangeEvent, FC, Fragment, ReactNode, useMemo, useState } from 'react'
import { CheckBox } from '@dtx-company/shared-components/src/components/atoms/Checkbox/index'
import { CheckboxToggleGroup } from './CheckboxToggleGroup'
import { ContactCollectionFormDisplayTypes, CustomTermsFileUploadTypes, FormFields } from './types'
import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { DescriptionInput, TitleInput } from '../components/Inputs'
import { DisplayToggle } from '../components/DisplayToggle'
import { ExternalCollectionCheckboxes } from './subcomponents/ExternalCollectionCheckboxes'
import {
  FileUploadInput,
  MaskType,
  Modal,
  ModalVariants,
  theme
} from '@dtx-company/shared-components/src'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormProps, StyledForm } from '../components/Layout'
import { FormTitle } from '../components/Title'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { InnerLinkCard } from '../../../components/flowpage/FlowpageLinkCard'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { LockedFeatureWrapper } from '../../../../../common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { SettingsAndSaveLink } from '../components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { SuccessScreen } from './SuccessScreen'
import { TermsAndConditions } from './TermsAndConditions'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { getCurrentPageGate } from '@app/common/src/utils/getCurrentPageGate'
import { getMultipleGatesErrorMessage } from './utils/getMultipleGatesErrorMessage'
import { getThirdPartyIntegrations } from './utils'
import { getValidatedActionData } from '../typeUtils'
import { linkBlueOutline, paperclip } from '@app/code/src/assets'
import { sendSuccessNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { shouldProGateThisCRMFeature, useContactCollectionFormState } from './hooks'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCRMFormWrapperStyles } from './hooks/useCRMFormWrapperStyles'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useIsMobile } from '@dtx-company/true-common/src/hooks/useIsMobile'
import { useLockedFeatureAction } from '../../../../../common/src/components/LockedFeatureModal/hooks/useLockedFeatureAction'
import { useManagerSettings } from '../components/Manager/useManagerSettings'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils/modal'
import { usePageDisplayContext } from '../../../context/page-display'
import CollectionForm from '../../../components/flowpage/CollectionForm'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import messages from '@dtx-company/true-common/src/constants/messages'

const MAX_FILE_UPLOAD_SIZE = 20000000
const CustomTermsFileUpload: FC<CustomTermsFileUploadTypes> = ({
  setError,
  setValue,
  clearErrors,
  watchAll,
  children
}) => {
  return (
    <FileUploadInput
      {...stopPropagationEvents}
      id="terms-pdf-input"
      accept="application/pdf"
      handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
        const files = e?.target?.files
        if (files && files?.length > 0) {
          if (files[0].size > MAX_FILE_UPLOAD_SIZE) {
            setError(FormFields.CUSTOM_TERMS, {
              type: 'error',
              message: 'PDF is too large. Please upload a file less than 20MB.'
            })
          } else {
            setValue(
              FormFields.CUSTOM_TERMS,
              {
                ...watchAll.customTerms,
                pdf: files[0]
              },
              { shouldValidate: true }
            )
            clearErrors(FormFields.CUSTOM_TERMS)
          }
        } else {
          setValue(FormFields.CUSTOM_TERMS, {
            ...watchAll.customTerms,
            pdf: null
          })
          clearErrors(FormFields.CUSTOM_TERMS)
        }
      }}
    >
      {children}
    </FileUploadInput>
  )
}

export const Form: FC<FormProps> = ({ order, curr, handleClose }: FormProps) => {
  const page = useCurrentPage()
  const memoizedcurr = useMemo(() => curr, [curr])
  const gateLink = getCurrentPageGate(page?.links)
  const gateId = gateLink?.id
  const {
    handleSubmit,
    onSubmit,
    setValue,
    watchAll,
    disabled,
    edit,
    errors,
    setError,
    clearErrors,
    linkTheme,
    setLinkThemeValue
  } = useContactCollectionFormState(order, memoizedcurr, handleClose)
  const termsPDFUploaded = useMemo(() => watchAll.customTerms?.pdf instanceof File, [watchAll])
  const { hasOperation } = useAuthState()
  const gatedDisabled = shouldProGateThisCRMFeature(hasOperation('view:flowpage_paid_crm'))
  const [showImagePicker, setShowImagePicker] = useState(
    Boolean(watchAll?.thumbNailImgUrl) ?? false
  )
  const openLockedFeatureModal = useLockedFeatureAction({
    lockedFeatureName: 'view:flowpage_paid_crm'
  })

  return (
    <>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        title={
          <FormTitle
            icon={<Image src={icons.saveContactOutlined} alt="Contact Collection Icon" />}
            title={`${edit ? 'Edit this' : 'Add a'} contact collection form`}
          />
        }
      >
        <TitleInput
          value={watchAll.title}
          errors={errors}
          onChange={e => setValue(FormFields.TITLE, e.target.value, { shouldValidate: true })}
          label="Form Title"
        />
        <DescriptionInput
          value={watchAll.description}
          errors={errors}
          label="Form Description (optional)"
          onChange={e => setValue(FormFields.DESCRIPTION, e.target.value)}
          charMax={DESCRIPTION_MAX}
        />
        <Box justifyContent="space-between" width="100%">
          <Text>Information Type</Text>
          <Text>Required</Text>
        </Box>
        <CheckboxToggleGroup
          {...stopPropagationEvents}
          options={[
            {
              checkbox: {
                label: 'Email',
                checked: watchAll.fields.email,
                onChange: () => {
                  if (watchAll.fields.email) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      email: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      email: !watchAll.fields.email
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.email,
                disabled: !watchAll.fields.email,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      email: !watchAll?.required?.email
                    },
                    { shouldValidate: true }
                  )
              }
            },
            {
              checkbox: {
                label: 'Name',
                checked: watchAll.fields.displayName,
                onChange: () => {
                  if (watchAll.fields.displayName) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      displayName: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      displayName: !watchAll?.fields.displayName
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.displayName,
                disabled: !watchAll.fields.displayName,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      displayName: !watchAll?.required?.displayName
                    },
                    { shouldValidate: true }
                  )
              }
            },
            {
              checkbox: {
                label: 'Phone',
                checked: watchAll.fields.phone,
                onChange: () => {
                  if (watchAll.fields.phone) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      phone: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      phone: !watchAll?.fields.phone
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.phone,
                disabled: !watchAll.fields.phone,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      phone: !watchAll?.required?.phone
                    },
                    { shouldValidate: true }
                  )
              }
            },
            {
              checkbox: {
                label: 'Address',
                checked: watchAll.fields.address,
                onChange: () => {
                  if (watchAll.fields.address) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      address: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      address: !watchAll?.fields.address
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.address,
                disabled: !watchAll.fields.address,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      address: !watchAll?.required?.address
                    },
                    { shouldValidate: true }
                  )
              }
            },
            {
              checkbox: {
                label: 'Age',
                checked: watchAll.fields.age,
                onChange: () => {
                  if (watchAll.fields.age) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      age: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      age: !watchAll?.fields.age
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.age,
                disabled: !watchAll.fields.age,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      age: !watchAll?.required?.age
                    },
                    { shouldValidate: true }
                  )
              }
            },
            {
              checkbox: {
                label: 'Gender',
                checked: watchAll.fields.gender,
                onChange: () => {
                  if (watchAll.fields.gender) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      gender: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      gender: !watchAll?.fields.gender
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.gender,
                disabled: !watchAll.fields.gender,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      gender: !watchAll?.required?.gender
                    },
                    { shouldValidate: true }
                  )
              }
            },
            {
              checkbox: {
                label: 'Custom input field',
                checked: watchAll.fields.custom,
                onChange: () => {
                  if (watchAll.fields.custom) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      custom: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      custom: !watchAll?.fields.custom
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.custom,
                disabled: !watchAll.fields.custom,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      custom: !watchAll?.required?.custom
                    },
                    { shouldValidate: true }
                  )
              },
              shouldBeLocked: true,
              hiddenInput: {
                show: watchAll.fields.custom,
                input: (
                  <Input
                    type="text"
                    value={watchAll.customFieldLabel}
                    placeholder="Enter a label or question"
                    maxWidth="100%"
                    inputProps={{ style: { maxWidth: '100%' } }}
                    error={errors.customFieldLabel}
                    helperText={errors.customFieldLabel ? 'Please add a custom field label' : ''}
                    onChange={e =>
                      setValue(FormFields.CUSTOM_FIELD_LABEL, e.target.value, {
                        shouldValidate: true
                      })
                    }
                    {...stopPropagationEvents}
                  />
                )
              }
            },
            {
              checkbox: {
                label: 'Custom input field',
                checked: watchAll.fields.custom2,
                onChange: () => {
                  if (watchAll.fields.custom2) {
                    setValue(FormFields.REQUIRED, {
                      ...watchAll.required,
                      custom2: false
                    })
                  }
                  setValue(
                    FormFields.FIELDS,
                    {
                      ...watchAll.fields,
                      custom2: !watchAll?.fields.custom2
                    },
                    { shouldValidate: true }
                  )
                }
              },
              toggle: {
                checked: watchAll.required?.custom2,
                disabled: !watchAll.fields.custom2,
                onChange: () =>
                  setValue(
                    FormFields.REQUIRED,
                    {
                      ...watchAll.required,
                      custom2: !watchAll?.required?.custom2
                    },
                    { shouldValidate: true }
                  )
              },
              shouldBeLocked: true,
              hiddenInput: {
                show: watchAll.fields.custom2,
                input: (
                  <Input
                    type="text"
                    value={watchAll.customFieldLabel2 ?? ''}
                    placeholder="Enter a label or question"
                    maxWidth="100%"
                    inputProps={{ style: { maxWidth: '100%' } }}
                    error={errors.customFieldLabel2}
                    helperText={errors.customFieldLabel2 ? 'Please add a custom field label' : ''}
                    onChange={e =>
                      setValue(FormFields.CUSTOM_FIELD_LABEL_2, e.target.value, {
                        shouldValidate: true
                      })
                    }
                    {...stopPropagationEvents}
                  />
                )
              }
            }
          ]}
        />
        {errors.fields && (
          <Text color="status.errorDark">
            {errors?.fields?.message || 'Please select at least one collection type'}
          </Text>
        )}
        <Spacer mb="8px" />
        <LockedFeatureWrapper
          featureName="view:flowpage_paid_crm"
          onOpen={events.userClickedGatedCRMTerms}
          sx={{ alignItems: 'flex-start' }}
        >
          <Box flexDirection="column">
            <Text>Custom Terms &amp; Conditions</Text>
            <Spacer mb="4px" />
            <CheckBox
              padding="4px 0px"
              label="Add a link to your own Terms &amp; Conditions"
              checked={watchAll.customTerms?.enabled}
              onChange={() =>
                setValue(
                  FormFields.CUSTOM_TERMS,
                  {
                    ...watchAll.customTerms,
                    enabled: !watchAll.customTerms?.enabled
                  },
                  { shouldValidate: true }
                )
              }
              disabled={gatedDisabled}
              {...stopPropagationEvents}
            />
            {watchAll.customTerms?.enabled && (
              <Box width="100%" flexDirection="column">
                <Spacer mb="16px" />
                <Box
                  width="100%"
                  flexDirection={['column', 'row']}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Box width="100%">
                    <Input
                      id="custom-terms-url-input"
                      type="text"
                      maxWidth="100%"
                      value={
                        watchAll.customTerms?.pdf instanceof File
                          ? watchAll.customTerms.pdf.name
                          : watchAll.customTerms.pdf ?? ''
                      }
                      disabled={termsPDFUploaded || gatedDisabled}
                      endAdornment={
                        termsPDFUploaded ? (
                          <CustomTermsFileUpload
                            setError={setError}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            watchAll={watchAll}
                          >
                            <Box
                              display="flex"
                              margin="0"
                              textAlign="center"
                              border={`1px solid ${theme.colors.primary.flowBlue}`}
                              justifyContent="center"
                              alignItems=" center"
                              borderRadius="4px"
                              cursor="pointer"
                              mr="4px"
                              padding="7.5px 12px"
                              backgroundColor="secondary.backgroundDark"
                            >
                              <Text variant="button/general" color="primary.flowBlue">
                                Change
                              </Text>
                            </Box>
                          </CustomTermsFileUpload>
                        ) : undefined
                      }
                      startAdornment={
                        termsPDFUploaded ? (
                          <Image alt="pdf uploaded icon" src={paperclip} ml="2px" />
                        ) : undefined
                      }
                      inputProps={{
                        style: { paddingLeft: termsPDFUploaded ? '26px' : '17px' }
                      }}
                      placeholder="Enter a URL"
                      onChange={e =>
                        setValue(
                          FormFields.CUSTOM_TERMS,
                          {
                            ...watchAll.customTerms,
                            pdf: e.target.value
                          },
                          { shouldValidate: true }
                        )
                      }
                      {...stopPropagationEvents}
                    />
                  </Box>
                  <Text color="primary.grey" variant="body/medium" margin="10px 8px">
                    OR
                  </Text>
                  {termsPDFUploaded ? (
                    <Box minWidth={['100%', '40%']}>
                      <SecondaryButton
                        width="100%"
                        onClick={() =>
                          setValue(
                            FormFields.CUSTOM_TERMS,
                            {
                              ...watchAll.customTerms,
                              pdf: null
                            },
                            { shouldValidate: true }
                          )
                        }
                        disabled={gatedDisabled}
                        {...stopPropagationEvents}
                      >
                        <Box>
                          <Image
                            src={linkBlueOutline}
                            alt="Add Link icon"
                            width="20px"
                            height="20px"
                            mr="8px"
                          />
                          <Text variant="button/general" color="primary.flowBlue">
                            Add link{' '}
                          </Text>
                        </Box>
                      </SecondaryButton>
                    </Box>
                  ) : (
                    <Box width="100%">
                      <CustomTermsFileUpload
                        setError={setError}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        watchAll={watchAll}
                      >
                        <Box
                          display="flex"
                          margin="0"
                          textAlign="center"
                          border={`1px solid ${theme.colors.primary.flowBlue}`}
                          justifyContent="center"
                          alignItems=" center"
                          borderRadius="4px"
                          cursor="pointer"
                          mr="4px"
                          width={['calc(100vw - 32px)', '100%', '100%']}
                          padding="10px 12px"
                          backgroundColor="secondary.backgroundDark"
                        >
                          <Image
                            alt="upload icon"
                            height="20px"
                            width="20px"
                            src={icons.uploadBlue}
                            mr="8px"
                          />
                          <Text variant="button/general" margin="0" color="primary.flowBlue">
                            Upload PDF
                          </Text>
                        </Box>
                      </CustomTermsFileUpload>
                    </Box>
                  )}
                </Box>
                {errors.link?.message && (
                  <Text mt="8px" color="status.errorDark">
                    {errors.link?.message}
                  </Text>
                )}
                <Spacer mb="16px" />
                <DescriptionInput
                  disabled={gatedDisabled}
                  value={watchAll.customTerms?.disclaimer ?? ''}
                  label="Terms & Conditions Disclaimer (optional)"
                  placeholder="Enter a disclaimer"
                  errors={{ description: errors.customTerms?.disclaimer }}
                  onChange={e => {
                    setValue(
                      FormFields.CUSTOM_TERMS,
                      {
                        ...watchAll.customTerms,
                        disclaimer: e.target.value
                      },
                      { shouldValidate: true }
                    )
                  }}
                  charMax={CUSTOM_CRM_TERMS_MAX}
                  {...stopPropagationEvents}
                />
              </Box>
            )}
            {errors.customTerms && (
              <Text color="status.errorDark">{errors.customTerms.message}</Text>
            )}
          </Box>
        </LockedFeatureWrapper>
        <ExternalCollectionCheckboxes
          watchAll={watchAll}
          setValue={setValue}
          curr={memoizedcurr}
          errors={errors}
          setError={setError}
        />
        <Text>Add a custom success message</Text>
        <Spacer mb="8px" />
        <CheckBox
          checked={watchAll.successMessage.enabled}
          label="Add a custom message when a visitor successfully submits their contact information"
          onChange={() =>
            setValue(
              FormFields.SUCCESS_MESSAGE,
              {
                ...watchAll.successMessage,
                enabled: !watchAll.successMessage.enabled
              },
              { shouldValidate: true }
            )
          }
          {...stopPropagationEvents}
        />
        {watchAll.successMessage.enabled && (
          <>
            <Input
              labelProps={{ marginBottom: '8px' }}
              maxWidth="100%"
              type="text"
              label="Message Header"
              placeholder="Enter a header"
              error={errors?.successMessage?.header}
              helperText={errors.successMessage?.header?.message}
              value={watchAll.successMessage.header}
              onChange={e => {
                setValue(
                  `${FormFields.SUCCESS_MESSAGE}`,
                  { ...watchAll.successMessage, header: e.target.value },
                  { shouldValidate: true }
                )
              }}
              {...stopPropagationEvents}
            />
            <DescriptionInput
              label="Message Body"
              placeholder="Enter body"
              errors={{ description: errors?.successMessage?.body }}
              value={watchAll.successMessage.body ?? ''}
              onChange={e => {
                setValue(
                  `${FormFields.SUCCESS_MESSAGE}`,
                  { ...watchAll.successMessage, body: e.target.value },
                  { shouldValidate: true }
                )
              }}
              {...stopPropagationEvents}
              charMax={DESCRIPTION_MAX}
            />
          </>
        )}
        <Text variant="body/small">Add an image</Text>
        <Spacer mb="8px" />
        <CheckBox
          checked={showImagePicker}
          label="Add an image above your contact collection form"
          inputProps={{
            'aria-label': 'Add an image above your contact collection form'
          }}
          onChange={() => {
            if (showImagePicker) {
              setValue(FormFields.WIDGET_DISPLAY_TYPE, MaskType.NO_IMAGE)
              setValue(FormFields.IMAGE, undefined)
            } else {
              if (watchAll.widgetDisplayType === MaskType.NO_IMAGE) {
                setValue(FormFields.WIDGET_DISPLAY_TYPE, MaskType.SQUARE)
              }
            }
            setShowImagePicker(!showImagePicker)
          }}
          {...stopPropagationEvents}
        />
        {showImagePicker && (
          <BigImagePickerAndEditor
            imageUrl={watchAll.thumbNailImgUrl ?? ''}
            setValue={setValue}
            imageFormFieldName={FormFields.IMAGE}
            maskTypeFormFieldName={FormFields.WIDGET_DISPLAY_TYPE}
            showImageSizeWarningToast
            id="big-image-picker"
            deleteImage
          />
        )}
        <Box display="block">
          <Box display="flex" alignItems="center" justifyContent="left">
            <DisplayToggle
              displayOptions={[
                {
                  title: 'Embedded',
                  value: ContactCollectionFormDisplayTypes.EMBED
                },
                { title: 'Link', value: ContactCollectionFormDisplayTypes.DEFAULT },
                {
                  title: 'Pop-up',
                  value: ContactCollectionFormDisplayTypes.POPUP,
                  isLockedFeature: gatedDisabled
                }
              ]}
              displayType={watchAll.displayType}
              width={['auto']}
              onChange={(_e, type) => {
                if (
                  type === ContactCollectionFormDisplayTypes.POPUP &&
                  !hasOperation('view:flowpage_paid_crm')
                ) {
                  openLockedFeatureModal({})
                  return
                }
                // If the user selected a Pop-up display type (which makes the CRM form behave as a gate)
                // and the page has another gate that is not the current CRM form (an age gate or
                // another CRM popup) trigger display an error message to the user
                if (
                  type === ContactCollectionFormDisplayTypes.POPUP &&
                  gateId &&
                  gateId !== curr?.id
                ) {
                  const gateType = gateLink?.type
                  const message = getMultipleGatesErrorMessage(gateType)
                  setError(FormFields.DISPLAY_TYPE, { message })
                } else {
                  clearErrors(FormFields.DISPLAY_TYPE)
                }

                setValue(FormFields.DISPLAY_TYPE, type)
                if (type !== ContactCollectionFormDisplayTypes.POPUP) {
                  setValue(FormFields.FILLED_OUT_FORM_REQ, false)
                }
              }}
            />
          </Box>
          <Text
            color="status.errorDark"
            display={errors[FormFields.DISPLAY_TYPE] ? 'block' : 'none'}
            aria-live="assertive"
          >
            {errors?.[FormFields.DISPLAY_TYPE]?.message}
          </Text>
        </Box>
        {watchAll.displayType === ContactCollectionFormDisplayTypes.POPUP && (
          <Box width="100%">
            <Toggle
              toggleInputProps={{ style: { width: '60px' } }}
              backgroundColor="secondary.border"
              checked={watchAll.filledOutFormRequirement}
              onChange={() =>
                setValue(FormFields.FILLED_OUT_FORM_REQ, !watchAll.filledOutFormRequirement)
              }
              label="All visitors must fill out required inputs before being able to view your page"
              {...stopPropagationEvents}
            />
          </Box>
        )}
        {Object.keys(errors).length > 0 && (
          <Text color="status.errorDark">
            Some fields have errors above. Please double check your options and try again
          </Text>
        )}
        <Spacer mt="80px" />

        <Spacer mb="16px" />
        <LinkStyleGated
          hiddenFields={{
            shadowColor: true,
            borderColor: watchAll.displayType === ContactCollectionFormDisplayTypes.EMBED
          }}
          linkTheme={linkTheme}
          setLinkTheme={setLinkThemeValue}
        />

        <SettingsAndSaveLink disabled={disabled} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

/**
 * Wrapper which provides color, backgroundColor and other styles for the CRM form
 * link when rendered on a flowpage
 */
const CRMFormStyles = ({
  children,
  linkTheme,
  crmDisplayType
}: {
  children: ReactNode
  linkTheme: LinkInfoFragment['linkTheme']
  crmDisplayType: LinkInfoFragment['displayType']
}): JSX.Element => {
  const crmFormStyles = useCRMFormWrapperStyles({ linkTheme, crmDisplayType })

  return (
    <Box {...crmFormStyles} width="100%" display="block" px="21px" py="30px">
      {children}
    </Box>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview = false,
  editLinkMode,
  isEditLinkPreview
}: FlowpageProps) => {
  const {
    page: { slugName }
  } = usePageDisplayContext()
  const { setIsGateCRMFormSubmitted } = usePageDisplayContext()
  const { modalOpen, setClose, setOpen, props } = useModal(ModalTypes.COLLECTION)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { trackLinkClick, trackCRMWidgetAction } = useFlowpageAnalyticsCollector({
    isPreview: preview,
    isEditMode: Boolean(editLinkMode || isEditLinkPreview)
  })

  const mobile = useIsMobile()
  const actionData = getValidatedActionData<'contactCollection'>(link.actionData, 'fields')
  const isEmbeddedForm = ContactCollectionFormDisplayTypes.EMBED === link.displayType

  const handleSubmissionSuccess = (): void => {
    if (actionData?.successMessage?.enabled) {
      setShowSuccessMessage(true)
    } else {
      sendSuccessNotification(messages.collection.success)
    }

    if (link.displayType === ContactCollectionFormDisplayTypes.POPUP) {
      setIsGateCRMFormSubmitted(true)
    }

    // Default form appears in a modal. Automatically close the modal if no custom message
    // has been specified on successful submission
    if (link.displayType === ContactCollectionFormDisplayTypes.DEFAULT) {
      if (!actionData?.successMessage?.enabled) {
        setClose()
      }
    }
  }

  return link.displayType === ContactCollectionFormDisplayTypes.DEFAULT ? (
    <>
      <Box
        id={link.id}
        width="100%"
        display="block"
        onClick={() => {
          trackLinkClick({ link_id: link.id }, { useHeap: true })
          trackCRMWidgetAction({
            link_id: link.id,
            actionType: 'CRM_WIDGET_ACTION_TYPE_CLICK_TO_OPEN_MODAL',
            displayType: link.displayType
          })

          if (!preview && !editLinkMode) {
            const widgetObj = {
              provider: LinkProvider.WIDGET,
              type: 'contactCollection',
              id: 'contactCollection'
            }

            setOpen({ widgetObj: widgetObj, curr: link })
          }
        }}
      >
        <InnerLinkCard
          link={link}
          editLinkMode={editLinkMode}
          iconSrc={link.thumbNailImgUrl ?? undefined}
          preview={preview}
        />
      </Box>
      <Modal
        open={modalOpen && props?.curr?.id === link.id}
        disableAutoClose={!showSuccessMessage}
        onClose={() => {
          trackCRMWidgetAction({
            link_id: link.id,
            actionType: 'CRM_WIDGET_ACTION_TYPE_CLICK_TO_DISMISS_MODAL',
            displayType: link.displayType
          })
          setClose()
        }}
        variant={mobile ? ModalVariants.SHEET : ModalVariants.DESKTOP}
        wrapperProps={{ width: '444px', minHeight: 'min-content' }}
        top="unset"
        zIndex={10001}
      >
        <CRMFormStyles crmDisplayType={link.displayType} linkTheme={link.linkTheme}>
          {showSuccessMessage ? (
            <SuccessScreen
              successMessageObj={actionData?.successMessage}
              slugName={slugName}
              fontColor="inherit"
            />
          ) : (
            <CollectionForm
              handleSubmissionSuccess={handleSubmissionSuccess}
              link={props?.curr ?? undefined}
            />
          )}
          <TermsAndConditions
            customTermsObj={actionData?.customTerms}
            customTermsUrl={actionData?.link}
          />
        </CRMFormStyles>
      </Modal>
    </>
  ) : (
    <CRMFormStyles crmDisplayType={link.displayType} linkTheme={link.linkTheme}>
      {showSuccessMessage ? (
        <SuccessScreen
          successMessageObj={actionData?.successMessage}
          slugName={slugName}
          fontColor="inherit"
        />
      ) : (
        <CollectionForm
          embedded={isEmbeddedForm}
          link={link}
          handleSubmissionSuccess={handleSubmissionSuccess}
        />
      )}
      <TermsAndConditions
        customTermsObj={actionData?.customTerms}
        customTermsUrl={actionData?.link}
      />
    </CRMFormStyles>
  )
}

export const Manager: FC<ManagerProps> = props => {
  const integrations = getThirdPartyIntegrations(props.link.actionData)
  const { managerContainerStyles } = useManagerSettings(props.link, props.editWidget)
  const integrationContainerStyles = { ml: '290px', mb: '15px' }
  return (
    <DefaultManager {...props} iconUrl={'/icons/product/crm.svg'}>
      {integrations?.length > 0 && (
        <Box width="100%" justifyContent="flex-start" {...integrationContainerStyles}>
          <Text
            opacity={managerContainerStyles.textOpacity}
            variant="body/small"
            color="secondary.disabled"
          >
            Integrations:
          </Text>
          <Spacer mr="4px" />
          {integrations.map(integration =>
            integration ? (
              <Fragment key={integration}>
                <Image
                  height="24px"
                  width="24px"
                  src={CRM_THIRD_PARTY_ICONS[integration]}
                  opacity={managerContainerStyles.iconOpacity}
                />
                <Spacer mr="4px" />
              </Fragment>
            ) : (
              <></>
            )
          )}
        </Box>
      )}
    </DefaultManager>
  )
}
