import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ExternalCollectionService } from '../types'
import { FC } from 'react'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { useCheckApiKey } from '../hooks'
import Link from 'next/link'

const AccountSettingInputSection: FC<{
  provider: string
  actionData: ExternalCollectionService
  checkAccountCallback: () => Promise<string>
}> = ({ provider, actionData, checkAccountCallback }) => {
  const { existingApiKey } = useCheckApiKey({
    initApiKey: actionData.apiKey ?? '',
    checkAccountCallback
  })

  if (existingApiKey && actionData.apiKey !== existingApiKey) {
    return (
      <>
        <Box>
          <Text color="status.infoDark" mt="8px">
            {`A ${provider} integration has already been set up for your account and you can enable it for
          this Form. In order to edit the integration, please change it the `}
            <u>
              <Link href="#settings">Settings tab</Link>
            </u>
          </Text>
        </Box>
        <Spacer mb="16px" />
      </>
    )
  } else return <></>
}

export default AccountSettingInputSection
