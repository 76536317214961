import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CheckBox } from '@dtx-company/shared-components/src/components/atoms/Checkbox/index'
import { FC } from 'react'
import { LockedFeatureWrapper } from '../../../../../common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface CheckboxToggleOption {
  checkbox: {
    checked: boolean
    onChange: () => void
    label: string
  }
  toggle: {
    checked: boolean
    onChange?: () => void
    label?: string
    disabled?: boolean
    backgroundColor?: string
  }
  hiddenInput?: {
    show: boolean
    input: any
  }
  shouldBeLocked?: boolean
}
export interface CheckboxToggleGroupProps {
  options: CheckboxToggleOption[]
}

export const CheckboxToggleGroup: FC<CheckboxToggleGroupProps> = ({
  options
}: CheckboxToggleGroupProps) => {
  const { hasOperation } = useAuthState()

  return (
    <Box flexDirection="column" width="100%">
      {options.map((option: CheckboxToggleOption, idx) => {
        const gatedDisabled = !hasOperation('view:flowpage_paid_crm') && option.shouldBeLocked
        return (
          <LockedFeatureWrapper
            key={idx}
            featureName={gatedDisabled ? 'view:flowpage_paid_crm' : ''}
            sx={{
              flexDirection: 'row-reverse',
              alignItems: 'center',
              mb: idx !== options.length - 1 ? '22px' : '0',
              gap: 4
            }}
            onOpen={() => {
              events.userClickedGatedCRMIntegrations({})
            }}
            showTooltip={false}
          >
            <Box width="100%" mb={idx !== options.length - 1 && !gatedDisabled ? '22px' : '0'}>
              <Box width="100%" display="block" flexDirection="column">
                <Box
                  justifyContent="flex-start"
                  cursor={gatedDisabled ? 'pointer' : 'default'}
                  {...stopPropagationEvents}
                >
                  <Box justifyContent="space-between" width="100%" maxWidth="100%">
                    <CheckBox
                      padding={'4px 0px'}
                      label={option.checkbox.label}
                      disabled={gatedDisabled}
                      checked={option.checkbox.checked}
                      onChange={option.checkbox.onChange}
                      data-testid={`${option.checkbox.label}-${idx}-checkbox`}
                    />
                    <Toggle
                      disabled={gatedDisabled || option.toggle.disabled}
                      checked={option.toggle.checked}
                      onChange={option.toggle.onChange}
                      label={option.toggle.label}
                      backgroundColor={option.toggle.backgroundColor ?? 'secondary.border'}
                      data-testid={`${option.checkbox.label}-${idx}-toggle`}
                    />
                  </Box>
                </Box>
                {option.hiddenInput && option.hiddenInput.show && (
                  <>
                    <Spacer mb="16px" />
                    {option.hiddenInput.input}
                  </>
                )}
              </Box>
            </Box>
          </LockedFeatureWrapper>
        )
      })}
    </Box>
  )
}
