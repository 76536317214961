import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactCollectionForm } from './types'
import { FC } from 'react'
import { MarketingPageRoutes } from '@dtx-company/true-common/src/constants/routes'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import styled from 'styled-components'

const Link = styled.a`
  text-decoration: none;
`
export interface TermsAndConditionsPropsType {
  customTermsObj?: ContactCollectionForm['customTerms']
  customTermsUrl?: string
}

export const TermsAndConditions: FC<TermsAndConditionsPropsType> = ({
  customTermsObj,
  customTermsUrl
}) => {
  return (
    <Box>
      <Text color="inherit" textAlign="center" variant="body/small">
        {customTermsObj?.disclaimer ? (
          <>
            {customTermsObj?.disclaimer + ' '}
            <Link
              href={customTermsUrl || MarketingPageRoutes.TERMS_OF_USE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                as="span"
                display="inline"
                color="inherit"
                cursor="pointer"
                variant="body/small"
                fontWeight="bold"
                textDecoration="underline"
                data-testid="terms-and-conditions-link"
              >
                Terms &#38; Conditions
              </Text>
            </Link>
          </>
        ) : (
          <>
            By submitting, I agree to the&nbsp;
            <Link
              href={customTermsUrl || MarketingPageRoutes.TERMS_OF_USE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                as="span"
                color="inherit"
                display="inline"
                cursor="pointer"
                variant="body/small"
                fontWeight="bold"
                textDecoration="underline"
              >
                Terms of Use
              </Text>
            </Link>
            &nbsp;and&nbsp;
            <Link
              href={MarketingPageRoutes.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                as="span"
                color="inherit"
                display="inline"
                cursor="pointer"
                variant="body/small"
                fontWeight="bold"
                textDecoration="underline"
              >
                Privacy Policy
              </Text>
            </Link>
          </>
        )}
      </Text>
    </Box>
  )
}
