import { SVGProps, memo } from 'react'

function CheckSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 16 13" {...rest}>
      <g clipPath="url(#prefix__prefix__clip_check)">
        <path
          stroke={color || stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M0 8.32l3.84 3.84L16 0"
        />
      </g>
      <defs>
        <clipPath id="prefix__prefix__clip_check">
          <path fill="#fff" d="M0 0h16v12.16H0V0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Check = memo(CheckSvg)

function CheckOutlinedSvg({
  fill = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 12 12" fontSize="inherit" {...rest}>
      <path
        fill={color || fill}
        d="M4.76499011,6.7673683 L8.2641848,3.26100386 C8.61147835,2.91299871 9.15190114,2.91299871 9.49919469,3.26100386 C9.51164115,3.27347582 9.52370806,3.28637357 9.53537662,3.29967699 C9.83511755,3.64141434 9.81891834,4.17816549 9.49919469,4.49854425 L5.18121271,8.82537365 C4.94885368,9.05820878 4.58112654,9.05820878 4.34876751,8.82537365 L2.50080531,6.97362503 C2.48835885,6.96115307 2.47629194,6.94825532 2.46462338,6.93495189 C2.16488245,6.59321455 2.18108166,6.0564634 2.50080531,5.73608464 C2.84809886,5.3880795 3.38852165,5.3880795 3.7358152,5.73608464 L4.76499011,6.7673683 Z"
      />
    </svg>
  )
}

export const CheckFilled = memo(CheckOutlinedSvg)
