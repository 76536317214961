import * as icons from '../../../../assets/icons'
import { CRM, CRMLabels } from '@dtx-company/true-common/src/types/crmThirdParty'
import { FormFields, IntegrationInputConfig, IntegrationsInputsArgs } from '../types'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { accountHasHubSpotApiKey } from '@dtx-company/inter-app/src/redux/slices/pageEditor/hubSpotEditing'
import {
  flowpageIconFilled,
  hubspotColor,
  klaviyoColor,
  mailchimpColor,
  salesforceColor,
  zapierColor
} from '@app/code/src/assets'
import { getPlanTypeName } from '@app/common/src/utils/getPlanTypeName'
import { stopPropagationEvents } from '../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { testZapierWebhook } from '../utils'
import { useClientHasMailchimpKey } from '@app/common/src/hooks/useClientHasMaillchimpKey'
import { useDebouncedCallback } from 'use-debounce'
import { useLazyAccountInfoQuery } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/pageQueries'
import { useState } from 'react'
import AccountSettingInputSection from '../subcomponents/AccountSettingInputSection'
import events from '@dtx-company/inter-app/src/event-tracking/events/crm'

export const useIntegrationsInputsConfigByCRM = ({
  formValues,
  setValue,
  setError,
  crrLinkHasMailchimpApiKey,
  errors
}: IntegrationsInputsArgs): Record<CRM, IntegrationInputConfig> => {
  const [showMailchimpError, setShowMailchimpError] = useState(false)
  const [showGoogleSheetsCopy, setShowGoogleSheetsCopy] = useState(false)
  const mailchimpInSlice = useClientHasMailchimpKey()
  const [trigger, result] = useLazyAccountInfoQuery()

  const accountHasSalesforceKey = async (): Promise<string> => {
    trigger({})
    return result.data?.me?.salesforceCode || ''
  }

  const disableMailchimpInput = !crrLinkHasMailchimpApiKey && mailchimpInSlice.length > 0
  const getMailchimpPlaceholder = (): string => {
    if (disableMailchimpInput) {
      if (mailchimpInSlice.length > 0) {
        return mailchimpInSlice
      }
      return 'API key already set'
    }
    return 'Enter API key'
  }
  const mailchimpPlaceholder = getMailchimpPlaceholder()
  const testZapierWebhookDebounced = useDebouncedCallback(async (webhookUrl: string) => {
    try {
      await testZapierWebhook(webhookUrl)
      setError(FormFields.ZAPIER, {
        type: 'info',
        message:
          'We sent some data to your webhook to test it. Check your zap to verify the test was successful'
      })
    } catch (err) {
      setError(FormFields.ZAPIER, {
        type: 'error',
        message:
          'We attempted to send some data to your webhook but we were unsuccessful. Please make sure the URL is complete and check for typos.'
      })
    }
  }, 300)
  return {
    [CRM.FLOWPAGE]: {
      label: CRMLabels.FLOWPAGE,
      checked: true,
      disabled: true,
      labelProps: { color: 'black' },
      iconSrc: flowpageIconFilled,
      postScript: '(see in Settings)',
      checkBoxOnChange: () => {
        //pass
      }
    },
    [CRM.GOOGLESHEETS]: {
      label: CRMLabels.GOOGLESHEETS,
      checked: formValues.googleSheets.enabled,
      iconSrc: icons.googlesheets,
      postScript: '(see in Settings)',
      labelProps: { color: 'black' },
      checkBoxOnChange: () => {
        if ((formValues.googleSheets.url ?? '').length > 0) {
          setValue(FormFields.GOOGLESHEETS, {
            ...formValues.googleSheets,
            enabled: !formValues.googleSheets.enabled
          })
        } else {
          setShowGoogleSheetsCopy(true)
        }
      },
      gatedFeatureTooltipText: `Upgrade to ${getPlanTypeName(
        PlanType.PRO
      )} to connect CRM data that you collect with Flowpage directly to Google Sheets.`,
      inputSection: showGoogleSheetsCopy ? (
        <Text marginBottom="14px" padding="0">
          Visit the Settings tab after saving this link to start collecting contacts in a Google
          Sheet.
        </Text>
      ) : undefined
    },
    [CRM.ZAPIER]: {
      label: CRMLabels.ZAPIER,
      checked: formValues.zapier.enabled,
      iconSrc: zapierColor,
      labelProps: { color: 'black' },
      checkBoxOnChange: () => setValue(FormFields.ZAPIER, { enabled: !formValues.zapier.enabled }),
      gatedFeatureTooltipText: `Upgrade to ${getPlanTypeName(
        PlanType.PRO_PLUS_ORG
      )} to connect CRM data that you collect with Flowpage to your other apps with Zapier.`,
      inputSection: (
        <>
          <Input
            labelProps={{ marginBottom: '8px' }}
            maxWidth="100%"
            type="text"
            label="Zapier Custom Webhook URL"
            placeholder="Enter custom webhook URL"
            value={formValues.zapier.url}
            onChange={e => {
              setValue(
                FormFields.ZAPIER,
                { ...formValues.zapier, url: e.target.value },
                { shouldValidate: true }
              )
              testZapierWebhookDebounced(e.target.value)
            }}
            {...stopPropagationEvents}
          />
          {errors.zapier && (
            <Text
              mt="8px"
              color={errors.zapier.type === 'error' ? 'status.errorDark' : 'status.infoDark'}
            >
              {errors.zapier?.message}
            </Text>
          )}
          <Spacer mb="16px" />
        </>
      )
    },
    [CRM.MAILCHIMP]: {
      label: CRMLabels.MAILCHIMP,
      checked: formValues.mailChimp.enabled || disableMailchimpInput,
      iconSrc: mailchimpColor,
      disabled: false,
      inputProps: { readOnly: disableMailchimpInput },
      labelProps: { color: 'black' },
      checkBoxOnChange: () => {
        if (!disableMailchimpInput) {
          setValue(FormFields.MAILCHIMP, { enabled: !formValues.mailChimp.enabled })
          setShowMailchimpError(false)
        } else {
          setShowMailchimpError(true)
        }
      },
      tooltip: {
        content:
          'Mailchimp integrations will apply to all contact collection forms added to your Flowpage. In order to edit the integration, you will have to edit it on the form where it was initially set up.'
      },
      gatedFeatureTooltipText: `Upgrade to ${getPlanTypeName(
        PlanType.PRO
      )} to connect CRM data that you collect with Flowpage directly to your Mailchimp instance.`,
      inputSection: (
        <>
          <Text>Email address must be set to required on this form.</Text>
          <Spacer mb="8px" />
          <Input
            labelProps={{ marginBottom: '8px' }}
            maxWidth="100%"
            type="text"
            label="Mailchimp API Key"
            placeholder={mailchimpPlaceholder}
            value={formValues.mailChimp.url}
            onFocus={() => {
              if (disableMailchimpInput) {
                setShowMailchimpError(true)
                document.querySelectorAll(':focus').forEach(el => (el as HTMLInputElement).blur())
              }
            }}
            onChange={e => {
              if (disableMailchimpInput) {
                setShowMailchimpError(true)
              } else {
                setValue(
                  FormFields.MAILCHIMP,
                  { ...formValues.mailChimp, url: e.target.value },
                  { shouldValidate: true }
                )
              }
            }}
            {...stopPropagationEvents}
          />
          {showMailchimpError && disableMailchimpInput && (
            <Text color="status.errorDark" mt="8px">
              A mailchimp integration has already been set up for your Flowpage. In order to edit
              the integration, you will have to edit it on the form where it was initially set up.
            </Text>
          )}
          <Spacer mb="16px" />
        </>
      )
    },
    [CRM.KLAVIYO]: {
      label: CRMLabels.KLAVIYO,
      checked: formValues.klaviyo.enabled,
      iconSrc: klaviyoColor,
      labelProps: { color: 'black' },
      checkBoxOnChange: () =>
        setValue(FormFields.KLAVIYO, {
          ...formValues.klaviyo,
          enabled: !formValues.klaviyo.enabled
        }),
      gatedFeatureTooltipText: `Upgrade to ${getPlanTypeName(
        PlanType.PRO_FLEX
      )} to connect CRM data that you collect with Flowpage directly to your Klaviyo instance.`,
      inputSection: (
        <>
          <Text>
            This feature collects email addresses. The email field will be set to required.
          </Text>
          <Spacer mb="8px" />
          <Input
            labelProps={{ marginBottom: '8px' }}
            maxWidth="100%"
            type="text"
            label="Klaviyo API Key"
            placeholder="Enter Klaviyo API Key"
            value={formValues.klaviyo.url}
            onChange={e => {
              setValue(
                FormFields.KLAVIYO,
                { ...formValues.klaviyo, url: e.target.value },
                { shouldValidate: true }
              )
            }}
            {...stopPropagationEvents}
          />
          <Input
            labelProps={{ marginBottom: '8px' }}
            maxWidth="100%"
            type="text"
            label="Klaviyo List ID"
            placeholder="List ID (optional)"
            value={formValues.klaviyo.customListId}
            onChange={e => {
              setValue(
                FormFields.KLAVIYO,
                { ...formValues.klaviyo, customListId: e.target.value },
                { shouldValidate: true }
              )
            }}
            {...stopPropagationEvents}
          />
          {errors.klaviyo && (
            <Text
              mt="8px"
              color={errors.zapier.type === 'error' ? 'status.errorDark' : 'status.infoDark'}
            >
              {errors.klaviyo?.message}
            </Text>
          )}
          <Spacer mb="16px" />
        </>
      )
    },
    [CRM.HUBSPOT]: {
      label: CRMLabels.HUBSPOT,
      checked: formValues.hubspot?.enabled ?? false,
      iconSrc: hubspotColor,
      labelProps: { color: 'black' },
      checkBoxOnChange: () => {
        setValue(FormFields.HUBSPOT, {
          ...formValues.hubspot,
          enabled: !formValues.hubspot.enabled
        })
      },
      tooltip: {
        content:
          'A HubSpot integration is setup once and can be used on multiple CRM forms, even in different Flowpages. In order to edit or setup the integration, do so in the Settings tab'
      },
      gatedFeatureTooltipText: `Upgrade to ${getPlanTypeName(
        PlanType.PRO_FLEX
      )} to connect CRM data that you collect with Flowpage directly to your Hubspot instance.`,

      inputSection: (
        <AccountSettingInputSection
          provider="HubSpot"
          checkAccountCallback={accountHasHubSpotApiKey}
          actionData={formValues.hubspot}
        />
      )
    },
    [CRM.SALESFORCE]: {
      label: CRMLabels.SALESFORCE,
      checked: formValues.salesforce?.enabled ?? false,
      iconSrc: salesforceColor,
      labelProps: { color: 'black' },
      checkBoxOnChange: () => {
        events.Flowpage_CRM_Clicked_SalesforceCheckbox({
          click_action: formValues.salesforce?.enabled ? 'unselected' : 'selected'
        })
        setValue(FormFields.SALESFORCE, {
          ...formValues.salesforce,
          enabled: !formValues.salesforce.enabled
        })
      },
      tooltip: {
        content:
          'A Salesforce integration is setup once and can be used on multiple CRM forms, even in different Flowpages. In order to edit or setup the integration, do so in the Settings tab'
      },
      gatedFeatureTooltipText: `Upgrade to ${getPlanTypeName(
        PlanType.PRO_FLEX
      )} to connect CRM data that you collect with Flowpage directly to your Salesforce instance.`,
      inputSection: (
        <AccountSettingInputSection
          checkAccountCallback={accountHasSalesforceKey}
          actionData={formValues.salesforce}
          provider="Salesforce"
        />
      )
    }
  }
}
