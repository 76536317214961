import { Box } from '../Box'
import { CheckBoxBase } from './styles'
import { CheckboxProps } from '../../../types'
import { FC } from 'react'
import { Text } from '../Text'
export const CheckBox: FC<CheckboxProps> = ({
  checked = false,
  disabled,
  error = false,
  inputProps,
  label = '',
  labelProps,
  name,
  onBlur,
  onChange,
  onFocus,
  textComponent,
  value,
  textVariant = 'checkbox/regular',
  ...rest
}: CheckboxProps) => {
  return (
    <Box display="block" {...rest}>
      <CheckBoxBase
        className="checkbox-label"
        error={error}
        disabled={disabled}
        readOnly={inputProps?.readOnly}
      >
        <input
          data-testid="checkbox"
          type="checkbox"
          checked={checked}
          value={value}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          aria-label="checkbox"
          {...inputProps}
        />
        <span className="checkbox-custom" />
        {label && (
          <Text
            lineHeight="initial"
            variant={textVariant}
            component={textComponent}
            pt="3px"
            pl={36}
            aria-label="checkbox-label"
            color={disabled ? 'secondary.disabled' : 'primary.black'}
            data-testid="checkbox-label"
            {...labelProps}
          >
            {label}
          </Text>
        )}
      </CheckBoxBase>
    </Box>
  )
}
