import {
  ACCOUNT_CREATE_OR_UPDATE_HUBSPOT_INTEGRATION,
  DELETE_HUBSPOT_INTEGRATION
} from '@dtx-company/flow-codegen/src/page/mutations'
import { ACCOUNT_HAS_HUBSPOT } from '@dtx-company/flow-codegen/src/page/queries'
import { pageGqlFetcher } from '../../../services/gqlFetcher'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'

export async function accountHasHubSpotApiKey(): Promise<string> {
  try {
    const resp = await pageGqlFetcher<{ hasHubSpotIntegration: string | null }>(ACCOUNT_HAS_HUBSPOT)
    return resp.hasHubSpotIntegration ? resp.hasHubSpotIntegration : ''
  } catch (err) {
    console.error('Error getting HubSpot', err)
    return ''
  }
}

export async function createOrUpdateAccountHubSpotIntegration(
  apiKey: string
): Promise<boolean | undefined> {
  try {
    const resp = await pageGqlFetcher<{ createOrUpdateHubSpotIntegration: boolean }>(
      ACCOUNT_CREATE_OR_UPDATE_HUBSPOT_INTEGRATION,
      { apiKey }
    )
    sendSuccessNotification('HubSpot API integration created or updated')
    return Boolean(resp?.createOrUpdateHubSpotIntegration)
  } catch (err) {
    console.error(err)
    sendErrorNotification(
      'Error creating or updating API key. Please verify it is correct and try again or contact support'
    )
  }
}

export async function deleteAccountHubSpotIntegration(): Promise<boolean | undefined> {
  try {
    const resp = await pageGqlFetcher<{ deleteHubSpotIntegration: boolean }>(
      DELETE_HUBSPOT_INTEGRATION
    )
    sendSuccessNotification('HubSpot integration deleted succesfully')
    return Boolean(resp?.deleteHubSpotIntegration)
  } catch (err) {
    console.error('Error deleting HubSpot', err)
    sendErrorNotification('Error deleting HubSpot integration. Please try again or contact support')
  }
}
