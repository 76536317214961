import { ActionDataTypes } from '../types'
import { ContactCollectionForm } from './types'
import { ContactInfoCapture } from '@dtx-company/flow-codegen/src/page/generated.types'
import { CrmThirdParty } from '@dtx-company/true-common/src/types/crmThirdParty'
import { addProtocol } from '../../../components/profile/LinkForm/utils'

export async function writeToZapier(webhookUrl: string, info: ContactInfoCapture): Promise<void> {
  try {
    await fetch(webhookUrl, { method: 'POST', body: JSON.stringify(info) }).then(res => res.json())
  } catch (err) {
    console.error(err)
  }
}

export async function testZapierWebhook(webhookUrl: string): Promise<void> {
  try {
    await fetch(webhookUrl, {
      method: 'POST',
      // Todo: Update this test payload to be dynamic and include the fields that the user
      // has selected to be included in their form
      body: JSON.stringify({
        displayName: 'John Doe',
        email: 'jhon@mail.com',
        phone: '19175551234'
      })
    }).then(res => res.json())
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getThirdPartyIntegrations = (
  actionData: ActionDataTypes['contactCollection']
): (CrmThirdParty | null)[] => {
  const crmTypes = []
  if (actionData?.zapier?.enabled) crmTypes.push(CrmThirdParty.ZAPIER)
  if (actionData?.googleSheets?.enabled) crmTypes.push(CrmThirdParty.GOOGLESHEETS)
  if (actionData?.mailChimp?.enabled) crmTypes.push(CrmThirdParty.MAILCHIMP)
  if (actionData?.klaviyo?.enabled) crmTypes.push(CrmThirdParty.KLAVIYO)
  if (actionData?.hubspot?.enabled) crmTypes.push(CrmThirdParty.HUBSPOT)

  return crmTypes
}

export const buildActionData = ({
  fields: contactFields,
  required,
  customTerms,
  widgetDisplayType,
  customFieldLabel,
  customFieldLabel2,
  zapier,
  mailChimp,
  googleSheets,
  klaviyo,
  hubspot,
  salesforce,
  successMessage,
  filledOutFormRequirement
}: ContactCollectionForm): ActionDataTypes['contactCollection'] => {
  const resolvedContactFields = { ...contactFields }
  const resolvedRequiredFields = { ...required }
  // auto-set email field to enabled and required if integrations enabled
  if (klaviyo?.enabled || mailChimp?.enabled) {
    resolvedContactFields.email = true
    resolvedRequiredFields.email = true
  }

  const contactTypes: string[] = []
  Object.entries(resolvedContactFields).forEach(
    ([k, v]) => v && contactTypes.push(k === 'displayName' ? 'dname' : k)
  )
  const requiredFields: string[] = []
  Object.entries(resolvedRequiredFields).forEach(
    ([k, v]) => v && requiredFields.push(k === 'displayName' ? 'dname' : k)
  )

  const pdfIsFile = customTerms?.pdf instanceof File

  const customTermsWithoutPDF = {
    enabled: customTerms.enabled,
    ...(customTerms?.disclaimer && { disclaimer: customTerms?.disclaimer })
  }
  const externalPdf = !pdfIsFile && customTerms?.pdf ? addProtocol(customTerms?.pdf as string) : ''
  let resolvedGoogleSheets = { ...googleSheets }
  if (!googleSheets.enabled) {
    resolvedGoogleSheets = { enabled: false }
  }
  return {
    fields: contactTypes as ActionDataTypes['contactCollection']['fields'],
    widgetDisplayType,
    required: requiredFields as ActionDataTypes['contactCollection']['required'],
    customFieldLabel,
    customFieldLabel2,
    customTerms: customTermsWithoutPDF,
    zapier,
    mailChimp,
    salesforce,
    googleSheets: resolvedGoogleSheets,
    klaviyo,
    hubspot,
    link:
      customTerms?.pdf instanceof File ? URL.createObjectURL(customTerms.pdf) : externalPdf ?? '',
    successMessage,
    filledOutFormRequirement
  }
}
