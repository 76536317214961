import { fireHeapEvent } from '../helpers'
import eventNames from '../constants/crm'

const e = {
  Flowpage_CRM_Clicked_AuthorizeSalesforce(): void {
    fireHeapEvent(eventNames.Flowpage_CRM_Clicked_AuthorizeSalesforce)
  },
  Flowpage_CRM_Clicked_AuthorizeInForm(): void {
    fireHeapEvent(eventNames.Flowpage_CRM_Clicked_AuthorizeInForm)
  },
  Flowpage_CRM_Clicked_SalesforceCheckbox({ click_action }: { click_action: string }): void {
    fireHeapEvent(eventNames.Flowpage_CRM_Clicked_SalesforceCheckbox, { click_action })
  },
  Flowpage_CRM_Clicked_ManagePageAfterSalesforceAuth(): void {
    fireHeapEvent(eventNames.Flowpage_CRM_Clicked_ManagePageAfterSalesforceAuth)
  },
  Flowpage_CRM_Clicked_DeleteSalesforce(): void {
    fireHeapEvent(eventNames.Flowpage_CRM_Clicked_DeleteSalesforce)
  },
  Flowpage_CRM_Clicked_ManagePageAfterGoogleAuth(): void {
    fireHeapEvent(eventNames.Flowpage_CRM_Clicked_ManagePageAfterGoogleAuth)
  }
}

export default e
