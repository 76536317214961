import { ErrorType, GqlErrorObjectType } from '../types/error.types'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'

export const DEFAULT_ERROR: ErrorType = {
  code: 'DEFAULT_ERROR',
  message: 'Something went wrong. Please try again.'
}

export const getGqlErrorMessage = (
  error: GqlErrorObjectType,
  defaultErrorMessage = DEFAULT_ERROR.message
): string => {
  return error?.response?.errors?.[0]?.message || defaultErrorMessage
}

export const getGqlErrorExtensions = (
  error: GqlErrorObjectType
): { code: string; reason: string } => {
  return error?.response?.errors?.[0]?.extensions
}

export const parseGqlError = (
  error: GqlErrorObjectType,
  defaultErrorMessage = DEFAULT_ERROR.message,
  defaultErrorCode = DEFAULT_ERROR.code
): ErrorType => {
  const message = getGqlErrorMessage(error, defaultErrorMessage)
  const code = error?.response?.errors?.[0]?.extensions?.code || defaultErrorCode
  return { message, code }
}

export const getGqlError = (
  error: GqlErrorObjectType,
  displayErrorNotification = true,
  label?: string,
  defaultErrorMessage = DEFAULT_ERROR.message,
  defaultErrorCode = DEFAULT_ERROR.code
): ErrorType => {
  const { message, code } = parseGqlError(error, defaultErrorMessage, defaultErrorCode)
  // get stack
  console.error(label || 'Error:', message, error.stack)
  if (displayErrorNotification) {
    sendErrorNotification(message)
  }
  return { message, code }
}
