import { ANIMATED_BUTTON_SIZE_VARIANTS, BUTTON_COLOR_VARIANTS } from '../../../constants'
import { AnimatedButtonProps } from '../../../types/atoms'
import { Box } from '../Box'
import { border, color, flexbox, fontSize, layout, position, space, variant } from 'styled-system'
import { theme } from '../../../theme'
import { transition } from '../../../configs'
import styled, { keyframes } from 'styled-components'

export const AnimatedButtonBase: React.ComponentType<AnimatedButtonProps> = styled.button<AnimatedButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  :focus {
    outline: none;
  }
  &.loading {
    pointer-events: none;
  }
  &.success {
    background: ${theme.colors.masterWeb.jade4};
    border: none;
    pointer-events: none;
  }
  transition: transform 0.2s ease, background 0.3s ease, box-shadow 0.3s ease;
  ${variant({
    prop: 'colorVariant',
    variants: BUTTON_COLOR_VARIANTS
  })}
  ${variant({
    prop: 'sizeVariant',
    variants: ANIMATED_BUTTON_SIZE_VARIANTS
  })}
  ${color}
  ${border}
  ${space}
  ${flexbox}
  ${layout}
  ${transition}
  ${position}
  ${({ disabled }) => (disabled ? `` : `cursor: pointer`)}
`

export const AnimatedButtonLables = styled.ul.withConfig({
  shouldForwardProp: prop => !['loading'].includes(prop)
})<{ success?: boolean; loading?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  list-style: none;
  text-align: center;
  transform-style: preserve-3d;
  transition: transform .3s ease;
  li {
    display: flex;
    justify-content: center;
    backface-visibility: hidden;
    transform-origin: 50% 50%;
    transform: rotateX(0deg) translateZ(10px);
    &:nth-child(2) {
      display: none;
      transform: rotateX(-90deg) translateZ(10px);
    }
    &:nth-child(3) {
      display: none;
      transform: rotateX(-180deg) translateZ(10px);
    }
  }

  ${({ success }) =>
    success &&
    ` transform: rotateX(180deg);
      li {
        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          display: flex
        }
      }`};

  ${({ loading }) =>
    loading &&
    ` transform: rotateX(90deg);
      li {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: flex
        }
      }
    `};
  }
`

const scale = keyframes`
  100% {
    transform: scale(1)
  }
 `

export const CheckContainer = styled(Box)`
  background-color: ${theme.colors.primary.white};
  border-radius: ${theme.radii[4]};
  align-items: center;
  height: inherit;
  width: inherit;
  margin-right: 10px;
  svg {
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
    transform-origin: 50% 50%;
    transform: scale(0);
    animation: ${scale} 300ms linear forwards;
  }
  ${layout}
  ${space}
  ${fontSize}
  ${position}
`
