import * as React from 'react'
import { AutocompleteType } from '../../../types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { InputProps } from '@dtx-company/shared-components/src'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { border, color, space, typography } from 'styled-system'
import { buildAddressObject } from './utils'
import { useRef } from 'react'
import styled from 'styled-components'
import useComponentSize from '@dtx-company/shared-components/src/hooks/useComponentSize'
import useScript from '@dtx-company/shared-components/src/utils'

interface CrmInputBaseProps extends InputProps {
  placeholderColor: string
}

const CrmInputBase = styled.input<CrmInputBaseProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  line-height: normal;
  outline: none;
  border: solid 1px ${({ theme }) => theme.colors.secondary.placeholder};
  border-radius: 4px;
  &::placeholder {
    line-height: inherit;
    color: ${props => {
      return props.placeholderColor ? props.placeholderColor : props.theme.colors.primary.black
    }};
    opacity: 0.6;
  }
  &::-moz-placeholder {
    line-height: revert;
  }
  &:focus {
    border: solid 1px ${({ focusBorderColor }) => focusBorderColor};
  }
  &:disabled {
    border: solid 1px ${({ theme }) => theme.colors.secondary.border};
  }
  font-family: ${({ theme }) => theme.fontFamily};
  ${border}
  ${typography}
  ${space}
  ${color}
`
export interface CrmInputProps extends InputProps {
  placeholderColor?: string
  color?: string
}

export interface CrmAddressInputProps extends CrmInputProps {
  setValue: UseFormMethods['setValue']
  value: string
}

export const CrmInput: React.FC<CrmInputProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = e => {},
  type = 'text',
  value,
  name,
  autoComplete,
  label,
  helperText,
  helperTextProps,
  fontSize = '16px',
  placeholder,
  height,
  width = '100%',
  startAdornment,
  disabled,
  error,
  id,
  labelProps,
  color = 'primary.black',
  backgroundColor,
  placeholderColor = 'primary.black',
  focusBorderColor = 'primary.black',
  ...rest
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const startRef = React.useRef<HTMLDivElement>(null)
  const { width: startRefWidth } = useComponentSize(startRef)
  const startWidth = `${Math.round(startRefWidth) + 6}px`
  const inputHeight = height ?? '44px'
  return (
    <Box display="block" width={width} maxWidth={rest.maxWidth}>
      {label && (
        <Text variant="body/small" color="inherit" {...labelProps}>
          {label}
        </Text>
      )}
      <Box
        height={inputHeight}
        position="relative"
        borderRadius={1}
        maxWidth="305px"
        width={width}
        {...rest}
      >
        {startAdornment && (
          <Box
            ref={startRef}
            height={inputHeight}
            alignItems="center"
            left="2px"
            position="absolute"
          >
            {startAdornment}
          </Box>
        )}
        <CrmInputBase
          disabled={disabled}
          onChange={onChange}
          type={type}
          pl={startAdornment ? startWidth : '17px'}
          placeholder={placeholder}
          fontSize={fontSize}
          id={id}
          name={name}
          autoComplete={autoComplete}
          aria-label={label}
          ref={inputRef}
          value={value}
          focusBorderColor={focusBorderColor}
          backgroundColor={error ? 'status.errorLight' : backgroundColor}
          placeholderColor={placeholderColor}
          color={color}
          borderColor={error ? 'status.errorDark' : undefined}
        />
      </Box>
      {helperText && (
        <Text
          color={error ? 'status.errorDark' : 'primary.black'}
          variant="body/small"
          {...helperTextProps}
          mb="8px"
        >
          {helperText}
        </Text>
      )}
    </Box>
  )
}

interface GoogleAddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

enum GoogleAddressComponentType {
  STREET_NUMBER = 'street_number',
  STREET = 'route',
  CITY = 'locality',
  REGION = 'administrative_area_level_1',
  ZIP_CODE = 'postal_code'
}

export const CrmAddressInput: React.FC<CrmAddressInputProps> = ({
  value,
  fontSize = '16px',
  labelProps,
  color = 'primary.black',
  backgroundColor,
  placeholderColor = 'primary.black',
  focusBorderColor = 'primary.black',
  setValue,
  error: inputError,
  helperText,
  name,
  autoComplete
}) => {
  const autoCompleteRef = useRef<AutocompleteType | null>(null)
  const [loaded, error] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_CLOUD_API_KEY}&libraries=places`
  )

  const handlePlaceSelect = async (): Promise<void> => {
    const addressObject = autoCompleteRef.current?.getPlace()
    const components = addressObject?.address_components
    const query = addressObject?.formatted_address
    const formattedAddressObject = buildAddressObject(components)
    if (query) {
      setValue('address', formattedAddressObject, { shouldValidate: true })
      setValue('addressString', query)
    }
  }
  const handleScriptLoad = (): void => {
    if (window.google) {
      const el = document.getElementById('location')
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(el as HTMLInputElement)
      autoCompleteRef.current?.setFields(['address_components', 'formatted_address'])
      autoCompleteRef.current?.addListener('place_changed', () => handlePlaceSelect())
      if (el) {
        window.google.maps.event.addDomListener(el, 'keydown', function (event) {
          if ((event as KeyboardEvent).key === 'Enter') {
            event.preventDefault()
          }
        })
      }
    }
  }

  React.useEffect(() => {
    if (loaded && !error) {
      handleScriptLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, error])

  return (
    <>
      <CrmInput
        onChange={e => setValue('addressString', e.target.value)}
        type="text"
        value={value}
        error={inputError}
        minWidth="100%"
        placeholder="Address"
        name={name}
        autoComplete={autoComplete}
        label="Address"
        fontSize={fontSize}
        helperText={helperText}
        labelProps={labelProps}
        id="location"
        aria-label={'Address'}
        focusBorderColor={focusBorderColor}
        backgroundColor={error ? 'status.errorLight' : backgroundColor}
        placeholderColor={placeholderColor}
        color={color}
        borderColor={error ? 'status.errorDark' : undefined}
      />
    </>
  )
}
