import { AnimatedButton } from '@dtx-company/shared-components/src/components/atoms/AnimatedButton/index'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CollectionProps } from './types'
import { ContactCollectionFormDisplayTypes } from '../../widgets/ContactCollectionForm/types'
import { CountrySelect, CountryType } from '../../util/CountryCodes'
import { CrmAddressInput, CrmInput } from './subcomponents'
import { FC, useRef } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { MaskType, Option } from '@dtx-company/shared-components/src'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { getCRMImageStyle } from '../../../components/widgets/BigImage/utils'
import { useCRMFormWrapperStyles } from '../../widgets/ContactCollectionForm/hooks/useCRMFormWrapperStyles'
import { useFlowpageCollectionFormState } from './hooks'
import styled from 'styled-components'

const StyledForm = styled.form`
  margin: auto;
  width: 100%;
`

const CollectionForm: FC<CollectionProps> = ({
  link,
  embedded = false,
  handleSubmissionSuccess
}: CollectionProps) => {
  const {
    isNameLink,
    isAgeLink,
    isPhoneLink,
    isAddressLink,
    isCustomLink,
    isCustom2Link,
    isEmailLink,
    isGenderLink,
    errors,
    values,
    setValue,
    isSubmitting,
    pageTheme,
    imageDisplay,
    submitForm,
    handleSubmit
  } = useFlowpageCollectionFormState({
    link,
    handleSubmissionSuccess,
    embedded
  })
  const phoneCountryCodeRef = useRef<HTMLInputElement>(null)

  const { color: submitButtonBorderColor } = useCRMFormWrapperStyles({
    linkTheme: link?.linkTheme,
    crmDisplayType: link?.displayType || ContactCollectionFormDisplayTypes.DEFAULT
  })

  if (!link) return null
  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      margin="auto"
      width="100%"
    >
      {imageDisplay !== MaskType.NO_IMAGE && link?.thumbNailImgUrl && (
        <Image
          src={link.thumbNailImgUrl as string}
          {...getCRMImageStyle(imageDisplay as MaskType)}
        />
      )}
      <Spacer mb="8px" />
      <Text textAlign="center" variant="body/large" fontWeight="bold" mb="8px" color="inherit">
        {link.title}
      </Text>
      {link.description && (
        <Text textAlign="center" maxWidth="305px" variant="body/small" mb="8px" color="inherit">
          {link.description}
        </Text>
      )}
      <StyledForm
        data-testid="collection-form-test"
        autoComplete="on"
        onSubmit={handleSubmit(submitForm)}
      >
        {isNameLink && (
          <CrmInput
            maxWidth="100%"
            type="text"
            name="name"
            autoComplete="name"
            placeholder="Name"
            label="Name"
            labelProps={{ marginBottom: '2px' }}
            value={values?.displayName}
            onChange={e => {
              setValue('displayName', e.target.value, { shouldValidate: true })
            }}
            error={!!errors.displayName}
            helperText={errors?.displayName?.message || ''}
            mb="4px"
            color={errors?.displayName && 'primary.black'}
          />
        )}
        {isEmailLink && (
          <CrmInput
            maxWidth="100%"
            type="text"
            name="email"
            autoComplete="email"
            placeholder={'youremail@gmail.com'}
            value={values?.email}
            onChange={e => {
              setValue('email', e.target.value)
            }}
            label={'Email'}
            labelProps={{ marginBottom: '2px' }}
            error={!!errors?.email}
            helperText={errors?.email ? errors.email.message : ''}
            mb="4px"
            color={errors?.email && 'primary.black'}
          />
        )}
        {isPhoneLink && (
          <CrmInput
            type="tel"
            name="tel-national"
            autoComplete="tel-national"
            maxWidth="100%"
            placeholder="(XXX)-XXX-XXXX"
            value={values?.phone}
            onChange={e => {
              setValue('phone', e.target.value)
            }}
            label="Phone"
            labelProps={{ marginBottom: '2px' }}
            error={Boolean(errors?.phone)}
            helperText={errors?.phone ? errors.phone.message : ''}
            mb="4px"
            startAdornment={
              <CountrySelect
                defaultValue={values.countryCode ?? '1'}
                onChange={(_, option) => {
                  const countryCodeTyped = (option as Option).value as CountryType
                  setValue('countryCode', countryCodeTyped?.phone, { shouldValidate: true })
                }}
                autocompleteRef={phoneCountryCodeRef}
              />
            }
            color={errors?.email && 'primary.black'}
          />
        )}
        {isAddressLink && (
          <>
            <CrmAddressInput
              type="text"
              name="street-address"
              autoComplete="street-address"
              value={values?.addressString}
              error={Boolean(errors.addressString)}
              inputProps={{
                id: 'location'
              }}
              helperText={errors?.addressString?.message || ''}
              color={errors?.email && 'primary.black'}
              labelProps={{ marginBottom: '2px' }}
              setValue={setValue}
            />
            <Spacer mb="6px" />
            <CrmInput
              name="address-line2"
              autoComplete="address-line2"
              onChange={e => setValue('streetAddress2', e.target.value)}
              type="text"
              value={values?.streetAddress2}
              error={!!errors.address}
              minWidth="100%"
              placeholder="Apt. Number/Suite/Other"
              label="Apt. Number/Suite/Other"
              labelProps={{ marginBottom: '2px' }}
              aria-label={'Address Line 2'}
              color={errors?.email && 'primary.black'}
              borderColor={errors.address ? 'status.errorDark' : undefined}
            />
          </>
        )}
        {isAgeLink && (
          <CrmInput
            maxWidth="100%"
            type="text"
            placeholder="Age"
            label="Age"
            labelProps={{ marginBottom: '2px' }}
            value={values?.age}
            onChange={e => {
              setValue('age', e.target.value, { shouldValidate: true })
            }}
            error={!!errors.age}
            helperText={errors?.age?.message || ''}
            mb="4px"
            color={errors?.email && 'primary.black'}
          />
        )}
        {isGenderLink && (
          <CrmInput
            maxWidth="100%"
            type="text"
            placeholder="Gender"
            label="Gender"
            labelProps={{ marginBottom: '2px' }}
            value={values?.gender}
            onChange={e => {
              setValue('gender', e.target.value, { shouldValidate: true })
            }}
            error={!!errors.gender}
            helperText={errors?.gender?.message || ''}
            mb="4px"
            color={errors?.email && 'primary.black'}
          />
        )}
        {isCustomLink && (
          <CrmInput
            maxWidth="100%"
            type="text"
            placeholder={link.actionData?.customFieldLabel}
            label={link.actionData?.customFieldLabel}
            labelProps={{ marginBottom: '2px' }}
            value={values?.custom}
            onChange={e => {
              setValue('custom', e.target.value, { shouldValidate: true })
            }}
            error={!!errors.custom}
            helperText={errors?.custom?.message || ''}
            mb="4px"
          />
        )}
        {isCustom2Link && (
          <CrmInput
            maxWidth="100%"
            type="text"
            placeholder={link.actionData?.customFieldLabel2}
            label={link.actionData?.customFieldLabel2}
            labelProps={{ marginBottom: '2px' }}
            value={values?.custom2}
            onChange={e => {
              setValue('custom2', e.target.value, { shouldValidate: true })
            }}
            error={!!errors.custom2}
            helperText={errors?.custom2?.message || ''}
            mb="4px"
          />
        )}
        <Spacer mb="16px" />
        <AnimatedButton
          sizeVariant="large"
          mt="10px"
          width="100%"
          maxWidth="unset"
          type="submit"
          id={`confetti-root-${link.id}`}
          loading={isSubmitting}
          position="relative"
          colorVariant={'invisible'}
          borderColor={submitButtonBorderColor || 'primary.black'}
          disabled={isSubmitting}
          label="Submit"
          loadingProps={{ label: 'Submitting' }}
        />
        <Spacer mt="8px" />
      </StyledForm>
    </Box>
  )
}

export default CollectionForm
