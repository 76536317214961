import { AuthorizeInfoCard } from './AuthorizeInfoCard'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CRM } from '@dtx-company/true-common/src/types/crmThirdParty'
import { CheckBox } from '@dtx-company/shared-components/src/components/atoms/Checkbox/index'
import { ContactCollectionForm } from '../types'
import { FC } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { LockedFeatureWrapper } from '../../../../../../common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Tooltip } from '@dtx-company/shared-components/src'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { questionMarkBlue } from '@app/code/src/assets'
import { shouldProGateThisCRMFeature } from '../hooks'
import { stopPropagationEvents } from '../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useIntegrationsInputsConfigByCRM } from '../hooks/useIntegrationsInputsConfigByCRM'

export function useCRMsShown(): CRM[] {
  const crms = []
  const canAccessCrm = useCurrentUserCrmAccess()
  crms.push(CRM.FLOWPAGE)
  crms.push(CRM.ZAPIER)
  crms.push(CRM.MAILCHIMP)
  if (canAccessCrm[CRM.KLAVIYO]) {
    crms.push(CRM.KLAVIYO)
  }
  crms.push(CRM.HUBSPOT)
  if (canAccessCrm[CRM.SALESFORCE]) {
    crms.push(CRM.SALESFORCE)
  }
  return crms
}

function useCurrentUserCrmAccess(): Record<CRM, boolean> {
  const { hasOperation } = useAuthState()

  return {
    [CRM.FLOWPAGE]: true, //every plan has access to this CRM
    [CRM.GOOGLESHEETS]: hasOperation('view:googlesheets_crm_option'),
    [CRM.MAILCHIMP]: hasOperation('view:mailchimp_crm_option'),
    [CRM.ZAPIER]: hasOperation('view:zapier_crm_option'),
    [CRM.KLAVIYO]: hasOperation('view:klaviyo_crm_option'),
    [CRM.SALESFORCE]: hasOperation('view:salesforce_integration'),
    [CRM.HUBSPOT]: hasOperation('view:hubspot_crm_option')
  }
}

const crmOperations = {
  [CRM.FLOWPAGE]: '',
  [CRM.GOOGLESHEETS]: 'view:googlesheets_crm_option',
  [CRM.MAILCHIMP]: 'view:mailchimp_crm_option',
  [CRM.ZAPIER]: 'view:zapier_crm_option',
  [CRM.KLAVIYO]: 'view:klaviyo_crm_option',
  [CRM.SALESFORCE]: 'view:salesforce_integration',
  [CRM.HUBSPOT]: 'view:hubspot_crm_option'
}

export const ExternalCollectionCheckboxes: FC<{
  watchAll: ContactCollectionForm
  setValue: UseFormMethods['setValue']
  setError: UseFormMethods['setError']
  errors: UseFormMethods['errors']
  curr: LinkType | undefined
}> = ({ watchAll, setValue, setError, errors, curr }) => {
  const { hasOperation } = useAuthState()
  const userHasOperations = {
    view: {
      hubspot_crm_option: hasOperation('view:hubspot_crm_option'),
      klaviyo_crm_option: hasOperation('view:klaviyo_crm_option'),
      salesforce_crm_option: hasOperation('view:salesforce_integration')
    }
  }

  const gatedDisabled = shouldProGateThisCRMFeature(hasOperation('view:flowpage_paid_crm'))
  const rowsConfig = useIntegrationsInputsConfigByCRM({
    formValues: watchAll,
    setValue,
    setError,
    userHasOperations: userHasOperations,
    crrLinkHasMailchimpApiKey: curr?.actionData.mailChimp?.url,
    errors
  })

  const crms = useCRMsShown()
  const canAccessCrm = useCurrentUserCrmAccess()

  return (
    <Box width="100%">
      <Box width="100%" flexDirection="column">
        <Text>Select where you would like responses stored </Text>
        <Spacer mb="8px" />
        <AuthorizeInfoCard salesforceData={watchAll.salesforce} hubspotData={watchAll.hubspot} />
        {crms.map(crm => {
          const row = rowsConfig[crm]
          const gateThisCrm = !canAccessCrm[crm]

          return (
            <Box
              flexDirection="column"
              width="100%"
              key={`${row?.label}-checkbox-row`}
              data-testid={`${row?.label}-checkbox-row`}
            >
              <LockedFeatureWrapper
                featureName={gateThisCrm ? crmOperations[crm] : ''}
                minimalIcon={true}
                iconSx={{ mx: 2 }}
              >
                <Box alignItems="center">
                  <CheckBox
                    checked={row?.checked}
                    disabled={row?.disabled || gatedDisabled || gateThisCrm}
                    label={row?.label}
                    labelProps={{ ...row?.labelProps, marginLeft: '20px' }}
                    onChange={row?.checkBoxOnChange}
                    inputProps={row?.inputProps}
                    {...stopPropagationEvents}
                  />
                  <Image
                    src={row?.iconSrc}
                    alt={`${row?.label} Logo`}
                    width="16px"
                    height="16px"
                    position="absolute"
                    marginLeft="32px"
                  />
                  {row?.postScript && (
                    <Text ml="4px" mt="4px" variant="input/label">
                      {row?.postScript}
                    </Text>
                  )}
                  {!gateThisCrm && row?.tooltip && (
                    <Tooltip
                      offset={20}
                      maxWidth="300px"
                      width="300px"
                      minHeight="0px"
                      maxHeight="120px"
                      marginTop="8px"
                      marginLeft="8px"
                      id="white-label-domain-tooltip"
                      content={row?.tooltip.content}
                      buttonImage={questionMarkBlue}
                      {...stopPropagationEvents}
                    />
                  )}
                </Box>
              </LockedFeatureWrapper>
              <Spacer mb="14px" />
              {row?.inputSection && (row?.checked || row?.label === 'Google Sheets') && (
                <>{row?.inputSection}</>
              )}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
