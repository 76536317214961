import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ExternalCollectionService } from '../types'
import { FC } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { accountHasHubSpotApiKey } from '@dtx-company/inter-app/src/redux/slices/pageEditor/hubSpotEditing'
import { useAccountInfoQuery } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/pageQueries'
import { useCheckApiKey } from '../hooks'
import Link from 'next/link'
import Skeleton from 'react-loading-skeleton'
import events from '@dtx-company/inter-app/src/event-tracking/events/crm'
import styled from 'styled-components'
const StyledU = styled.u`
  color: ${({ theme }) => theme.colors.primary.flowBlue};
`
export const AuthorizeInfoCard: FC<{
  salesforceData: ExternalCollectionService
  hubspotData: ExternalCollectionService
}> = ({
  salesforceData: { apiKey: initSalesforceKey, enabled: salesforceEnabled },
  hubspotData: { apiKey: initHubspotKey, enabled: hubspotEnabled }
}) => {
  const { data, isLoading: salesforceLoading } = useAccountInfoQuery({})
  const existingSalesforceKey = initSalesforceKey || data?.me?.salesforceCode
  const { existingApiKey: existingHubspotApiKey, loading: hubspotLoading } = useCheckApiKey({
    initApiKey: initHubspotKey ?? null,
    checkAccountCallback: accountHasHubSpotApiKey
  })
  const isLoading = salesforceLoading || hubspotLoading
  const salesforceMissingAuthorization = !existingSalesforceKey && salesforceEnabled
  const hubspotMissingAuthorization = !existingHubspotApiKey && hubspotEnabled
  const showAuthorizeCTACard =
    !isLoading && (hubspotMissingAuthorization || salesforceMissingAuthorization)

  return (
    <>
      {isLoading && (
        <Box data-testid="skeleton">
          <Skeleton width="100%" height={42} />
        </Box>
      )}
      {showAuthorizeCTACard && (
        <>
          <Box bg="#EDF5FF" border="1px solid #E2ECF8" padding="8px" flexDirection="column">
            <Box>
              <Image width="18px" height="18px" src={'/icons/product/information.svg'} />
              <Spacer mr="8px" />
              <Text>Authorize Extensions</Text>
            </Box>
            <Text fontSize="12px" ml="26px">
              Once you choose the storage extension then you have to authorize it under settings.{' '}
              <StyledU onClick={events.Flowpage_CRM_Clicked_AuthorizeInForm}>
                <Link href="#settings-contacts">Authorize Now</Link>
              </StyledU>
            </Text>
          </Box>
          <Spacer mb="8px" />
        </>
      )}
    </>
  )
}
