import { getHeap } from '@dtx-company/true-common/src/utils/heap'

/**
 * @deprecated use {@link fireAnalyticsEvent} from @dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent.ts instead
 */
export async function trackHeapEvent(
  e: string,
  meta?: Record<string, string | number>
): Promise<void> {
  getHeap()?.track(e, meta)
}
