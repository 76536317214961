import { AnimatedButtonBase, AnimatedButtonLables, CheckContainer } from './styles'
import { AnimatedButtonProps, TextVariantType } from '../../../types'
import { CheckFilled } from '../../../foundation/Icons/Actions'
import { FC } from 'react'
import { Icon } from '../Icon'
import { Spinner } from '../Spinner'
import { Text } from '../Text'
import { theme } from '../../../theme'

export const AnimatedButton: FC<AnimatedButtonProps> = (
  props: AnimatedButtonProps
): JSX.Element => {
  const {
    onClick,
    label,
    icon,
    iconAlt,
    iconProps,
    colorVariant = 'primary',
    sizeVariant = 'small',
    padding = '3px',
    textVariant,
    disabled,
    loading,
    loadingProps = {
      label: 'Loading'
    },
    success,
    successProps = {
      label: 'Success',
      checkIconProps: undefined
    },
    fontSize,
    fontWeight,
    lineHeight,
    ...rest
  } = props

  const { checkIconProps, label: successLabel = 'Success', ...successRest } = successProps
  const { label: loadingLabel = 'Loading', ...loadingRest } = loadingProps

  const getTextVariant = (): TextVariantType => {
    switch (sizeVariant) {
      case 'small':
        return 'body/small'
      case 'large':
        return 'body/medium'
      default:
        return 'body/small'
    }
  }

  const getVariant = (): TextVariantType | TextVariantType[] => {
    if (Array.isArray(sizeVariant)) return sizeVariant.map(getTextVariant)
    else return getTextVariant()
  }

  return (
    <AnimatedButtonBase
      onClick={onClick}
      colorVariant={colorVariant}
      sizeVariant={sizeVariant}
      className={`${success ? 'success' : ''} ${loading ? 'loading' : ''} withImg ${
        disabled ? 'disabled' : ''
      }`}
      disabled={disabled}
      padding={padding}
      {...rest}
    >
      <>
        {icon && !loading && !success && (
          <Icon src={icon} alt={iconAlt} marginRight="10px" {...iconProps} />
        )}
        {loading && <Spinner height="40px" width="40px" {...loadingRest} />}
        {success && (
          <CheckContainer {...successRest}>
            <CheckFilled fill={theme.colors.masterWeb.jade4} {...checkIconProps} />
          </CheckContainer>
        )}
      </>
      <AnimatedButtonLables success={success} loading={loading}>
        <li>
          <Text
            variant={textVariant || getVariant()}
            color="inherit"
            fontWeight={fontWeight}
            fontSize={fontSize}
            lineHeight={lineHeight}
            data-testid={label}
          >
            {label}
          </Text>
        </li>
        <li>
          <Text
            variant={textVariant || getVariant()}
            color="inherit"
            fontWeight={fontWeight}
            fontSize={fontSize}
            lineHeight={lineHeight}
          >
            {loadingLabel}
          </Text>
        </li>
        <li>
          <Text
            variant={textVariant || getVariant()}
            color={theme.colors.primary.white}
            fontWeight={fontWeight}
            fontSize={fontSize}
            lineHeight={lineHeight}
          >
            {successLabel}
          </Text>
        </li>
      </AnimatedButtonLables>
    </AnimatedButtonBase>
  )
}
