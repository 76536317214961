import { CenterBox } from '@dtx-company/shared-components/src/components/atoms/Box'
import { ContactCollectionForm } from './types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'

import { FC } from 'react'

export interface SuccessScreenPropsType {
  successMessageObj?: ContactCollectionForm['successMessage']
  slugName?: string | null
  fontColor?: string
}

export const SuccessScreen: FC<SuccessScreenPropsType> = ({
  successMessageObj,
  slugName,
  fontColor
}) => {
  let header = 'Thank You!'
  let body = `Your Information has successfully been sent to ${
    slugName ? slugName : 'the Flowpage Owner'
  }`

  if (successMessageObj?.enabled) {
    header = successMessageObj?.header || ''
    body = successMessageObj?.body || ''
  }

  return (
    <CenterBox flexDirection="column">
      <Text variant="display/small" color={fontColor || 'text.primary'}>
        {header}
      </Text>
      <Spacer mb="8px" />
      <Text variant="body/small" color={fontColor || 'text.primary'} textAlign="center">
        {body}
      </Text>
      <Spacer mb="16px" />
    </CenterBox>
  )
}
