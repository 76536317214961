import { CRMLabels } from '@dtx-company/true-common/src/types/crmThirdParty'
import { CheckboxProps, MaskType } from '@dtx-company/shared-components/src/types'
import { ErrorOption, UseFormMethods } from 'react-hook-form-deprecated'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'

export enum FormFields {
  TITLE = 'title',
  DESCRIPTION = 'description',
  FIELDS = 'fields',
  REQUIRED = 'required',
  DISPLAY_TYPE = 'displayType',
  CUSTOM_FIELD_LABEL = 'customFieldLabel',
  CUSTOM_FIELD_LABEL_2 = 'customFieldLabel2',
  CUSTOM_TERMS = 'customTerms',
  WIDGET_DISPLAY_TYPE = 'widgetDisplayType',
  ZAPIER = 'zapier',
  MAILCHIMP = 'mailChimp',
  GOOGLESHEETS = 'googleSheets',
  SALESFORCE = 'salesforce',
  KLAVIYO = 'klaviyo',
  HUBSPOT = 'hubspot',
  IMAGE = 'thumbNailImgUrl',
  LINK = 'link',
  SUCCESS_MESSAGE = 'successMessage',
  FILLED_OUT_FORM_REQ = 'filledOutFormRequirement'
}
export interface ContactCollectionFields {
  displayName: boolean
  email: boolean
  phone: boolean
  address: boolean
  age: boolean
  gender: boolean
  custom: boolean
  custom2: boolean
}

export enum ContactCollectionFormDisplayTypes {
  EMBED = 'embed',
  POPUP = 'popup',
  DEFAULT = 'default'
}

export interface CustomTermsTypes {
  enabled: boolean
  disclaimer?: string
  pdf?: File | null | string
}

export interface ExternalCollectionService {
  enabled: boolean
  url?: string
  spreadsheetId?: string
  apiKey?: string | null // klaviyo and hubspot
  customListId?: string // klaviyo
}

export interface SuccessMessage {
  enabled: boolean
  header?: string
  body?: string
}

export interface ContactCollectionForm {
  title: string
  description: string
  fields: ContactCollectionFields
  required: ContactCollectionFields
  customFieldLabel: string
  customFieldLabel2: string
  displayType: string
  widgetDisplayType: MaskType
  customTerms: CustomTermsTypes
  zapier: ExternalCollectionService
  mailChimp: ExternalCollectionService
  googleSheets: ExternalCollectionService
  salesforce: ExternalCollectionService
  klaviyo: ExternalCollectionService
  hubspot: ExternalCollectionService
  successMessage: SuccessMessage
  filledOutFormRequirement: boolean
  thumbNailImgUrl: string
}

export interface PreviewProps {
  values: ContactCollectionForm
}

export interface UseContactCollectionFormStateType {
  id: string
  handleSubmit: UseFormMethods['handleSubmit']
  onSubmit: (e: any) => Promise<void>
  setValue: UseFormMethods['setValue']
  watchAll: ContactCollectionForm
  disabled: boolean
  edit: boolean
  errors: any
  linkTheme: LinkType['linkTheme']
  setLinkThemeValue: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
  setError: (name: string, error: ErrorOption) => void
  clearErrors: (name?: string | string[] | undefined) => void
}

export interface CustomTermsFileUploadTypes {
  setError: (name: string, error: ErrorOption) => void
  setValue: UseFormMethods['setValue']
  clearErrors: (name?: string | string[] | undefined) => void
  watchAll: ContactCollectionForm
  children?: any
}

export interface AuthGoogleSheetsResponse {
  authorizeGoogleSheets?: string
}

export interface ValidateGoogleSheetResponse {
  hasValidGooglesheetId?: boolean
}

export interface IntegrationInputConfig {
  label: CRMLabels
  checked: boolean
  iconSrc: string
  postScript?: string
  checkBoxOnChange: () => void
  disabled?: boolean
  gatedFeatureTooltipText?: string
  labelProps: CheckboxProps['labelProps']
  inputProps?: CheckboxProps['inputProps']
  tooltip?: { content: string }
  inputSection?: JSX.Element
}

export type IntegrationInputConfigArr = (IntegrationInputConfig | false | undefined)[]
export interface IntegrationsInputsArgs {
  formValues: ContactCollectionForm
  setValue: UseFormMethods['setValue']
  setError: UseFormMethods['setError']
  userHasOperations?: {
    view?: {
      hubspot_crm_option?: boolean
      klaviyo_crm_option?: boolean
      salesforce_crm_option?: boolean
    }
  }
  crrLinkHasMailchimpApiKey: boolean
  errors: UseFormMethods['errors']
}

export type CRMFeatureFlags = {
  isGSheetsEnabled: boolean
}
