import { ContactCollectionFormDisplayTypes } from '../types'
import {
  LinkCardStyles,
  getLinkCardStyles
} from '../../../flowpage/FlowpageLinkCard/utils/getLinkCardStyles'
import { LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { useFlowpageTheme } from '../../../profile/Customize/hooks'

/**
 * Return CRM form styles like backgroundColor, color, border etc only when the form is
 * displaying embedded because otherwise it would render on top of a modal and that modal
 * style is fixed (greyish background and black text) to avoid color contrasting
 * issues we do not return any styles in that case
 */
export const useCRMFormWrapperStyles = ({
  linkTheme,
  crmDisplayType
}: {
  linkTheme: LinkInfoFragment['linkTheme']
  crmDisplayType: LinkInfoFragment['displayType']
}): LinkCardStyles => {
  const { theme: pageTheme } = useFlowpageTheme()
  const linkStyle = getLinkCardStyles({
    theme: pageTheme,
    linkTheme
  })

  // Only apply link styles to the embedded crm form
  if (crmDisplayType === ContactCollectionFormDisplayTypes.EMBED) return linkStyle
  else return {}
}
