import { PageGateTypes } from '@dtx-company/true-common/src/constants/gate'

/** Return the appropriate error message when a user is trying to add an crm gate when another gate (age gate, other crm, etc) already exists*/
export const getMultipleGatesErrorMessage = (gateType: string | undefined | null): string => {
  if (gateType === PageGateTypes.ageGate) {
    return "Cannot set this form as a pop-up because an age gate has already been applied. Please select 'Embedded' or 'Link' as the Display Type, or remove the age gate."
  } else if (gateType === PageGateTypes.contactCollection) {
    return "Cannot set this form as a pop-up because another pop-up form has already been applied. Please select 'Embedded' or 'Link' as the Display Type, or remove the other form."
  } else {
    // If a page has an nbaIdGate and the user is trying to add an ageGate or crm gate we might reach this case
    return "Cannot set this form as a pop-up because another pop-up has already been applied. Please select 'Embedded' or 'Link' as the Display Type, or remove the other popup."
  }
}
