import { CrmThirdParty } from '@dtx-company/true-common/src/types/crmThirdParty'
import { googlesheets } from '@app/page/src/assets/icons'
import { hubspotColor, klaviyoColor, mailchimpColor, zapierColor } from '@app/code/src/assets'

export const CRM_THIRD_PARTY_ICONS: Record<CrmThirdParty, string> = {
  [CrmThirdParty.MAILCHIMP]: mailchimpColor,
  [CrmThirdParty.GOOGLESHEETS]: googlesheets,
  [CrmThirdParty.ZAPIER]: zapierColor,
  [CrmThirdParty.KLAVIYO]: klaviyoColor,
  [CrmThirdParty.HUBSPOT]: hubspotColor
}
